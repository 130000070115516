/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

:root {
	--primary-color: #003a5d;  
	--secondary-color: #eba904;  
	--tertiary-color: #eba904;  

  --port-settings-scale-factor: 0.8

   
  --ion-safe-area-bottom: 22px;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
}

input[type=number] {
    -moz-appearance:textfield;
}
  
.searchbar-input.sc-ion-searchbar-md {
	border: 1px solid var(--ion-color-medium) !important;
	border-radius: 20px;
}

.sc-ion-searchbar-md-h {
	padding-top: 5px;
}

.sc-ion-buttons-ios-s ion-button {
	--color: var(--ion-color-dark);
}

.loadingBackground {
    ion-backdrop {
        opacity: .97 !important;
    }
}

.ios {
    ion-header {
        ion-toolbar {
            ion-title {
                text-align: center;
            }
            padding-top:2px;
        }
    }
}
.sc-ion-searchbar-ios-h {
  padding-top: 0px !important;
}

::-webkit-scrollbar {
  width: 7px !important;
  height: 7px !important;
}
  
/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: var(--ion-color-dark);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
ion-header {
    --ion-background-color: var(--ion-item-background);
}

ion-footer {
  --ion-background-color: var(--ion-item-background);
}

ion-toggle {
    height: 15px;
    --handle-height: 15px;
}
ion-button {
    font-size: calc(5px + .5vh);
}

.metadata-alert {
    .alert-input-wrapper {
        width: 50%;
        display: inline-block;
    }
}
.keys-display {
    text-align: center;
    .alert-title {
        text-align: center;
        display: flex;
        justify-content: center;
    }
    .ion-item {
        text-align: center;
        display: flex;
        justify-content: center;
    }
}
.key {
    font-weight: bolder;
    color: gray;
    margin-bottom: 0px;
    margin-top: 10px;
}
.value {
    margin-top: 0px;
    margin-bottom: 5px;
}
.rli_icon {
    margin-top: 5px;
    margin-right: 10px;
    width: 40px;
    float: left;
}

ion-title {
    text-align: right;
}

// Makes radio options in ion-select scalable for mobile and web
.select-interface-option.md.alert-tappable.alert-radio.alert-radio-button.ion-focusable.sc-ion-alert-md {
    white-space: normal !important;
    min-height: 80px;
}
// Makes checkboxes in ion-select scalable for mobile and web
.select-interface-option.md.alert-tappable.alert-checkbox.alert-checkbox-button.ion-focusable.sc-ion-alert-md {
    white-space: normal !important;
    min-height: 80px;
}
// Wraps checkbox in ion alerts on mobile
.alert-checkbox-label.sc-ion-alert-md {
    white-space: normal !important;
}
// Wraps radio option text in alerta on mobile
.alert-radio-label.sc-ion-alert-md {
    white-space: normal !important;
    margin: 20px;
}
.ion-select {
    --padding-bottom: 20px;
}
.alert-checkbox-group.sc-ion-alert-md {
    min-height: 95% !important;
}
.alert-message.sc-ion-alert-md {
  max-height: 90%;
}
.alert-wrapper {
    max-width: 500px !important;
    width: 95% !important;

    
    .alert-button-inner sc-ion-alert-md {
        margin: 20px;
    }
}


.alertPopUp .alert-wrapper {
    min-width: 35vw !important;
    
    .alert-title {
        font-size: 1.9em;
    }
    
    .alert-message {
        min-height: 40vh !important;
    }
}

.default-pop-over-style {
  --width: 575px;
  --max-height: 95vh;
  --max-width: 95vw;
}

.default-ion-select-popover {
    .popover-content {
        width: auto;
    ion-item {
    font-size: clamp(1vh, 1.2vh, 1.5vh)
    }
    }
}

.centering-class{
        text-align: center;
        display: flex;
    justify-content: center;
    .alert-title {
        text-align: center;
            display: flex;
        justify-content: center;
    }
    .alert-button-group.sc-ion-alert-md {
        text-align: end;
        display: inline-block;
    }
    .alert-button.sc-ion-alert-md {
        display: inline-block;
    }
}

#map {
    ion-row {
        background: rgba(6, 63, 91, 0.5);
        color: white;
    }
}
.centered_div {
    text-align: center;
    ion-icon {
        font-size: 5em;
        color: #063F5B;
    }	
}
.page {
  padding-top: .5vw;
  width: 96%;
  margin: auto;
} 
.block {
  width:95%;
  max-width: 800px;
}
.blockWide {
  max-width: 1200px;
}
.blockWidest {
  max-width: 2000px;
}

.listItemImage {
    width: 35%;
    max-width: 30vw;
    float: left;
    margin: auto;
    font-weight: bold;
    font-size: 1.1em;
    text-align: center;
    padding-top : 10px;
    padding-bottom : 10px;
    
    li {
        display: block;
    }
    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        overflow: hidden;
    }
    img {
        //max-width: 700px;
    }
}
.listItemText {
    text-align: right;
    width: 50%;
    font-size: .95em;
    
    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        overflow: hidden;
    }
}
.green_text {
    color: Lime;
    font-weight: bold;
    font-size: 22px;
    text-align: center;
}
.green_dot {
  height: 10px;
  width: 10px;
  background-color: #00FF00;
  border-radius: 50%;
  display: inline-block;
}
.red_dot {
  height: 10px;
  width: 10px;
  background-color: #FF0000;
  border-radius: 50%;
  display: inline-block;
}
.yellow_dot {
    height: 10px;
    width: 10px;  
    background-color: #f5e042;
    border-radius: 50%;
    display: inline-block;
}
//Tooltip Text
.tip {
    margin: auto;
    position: relative;
    display: inline-flex;
}
.tip .tipText {
    visibility: hidden;
    min-width: 130px;
    width: 17vh;
    background-color: #222428;
    color: White;
    font-size: .95vh;
    text-align: center;
    border-radius: 6px;
    padding: 7px;
    position: absolute;
    bottom: 100%;
    left: 30%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity .5s;
    z-index: 10001 !important;
}
.bottomText {
  bottom: -180% !important;
  left: -500% !important;
  position: absolute;
}
.tip2 {
    margin: auto;
    position: relative;
    display: inline-flex;
}
.tip2 .tipText2 {
    visibility: hidden;
    min-width: 150px;
    width: 17vh;
    
    background-color: #6e6e6e;
    color: White;
    font-size: .95vh;
    text-align: center;
    border-radius: 6px;
    padding: 7px;
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity .5s;
    z-index: 10001 !important;
}

.tooltip {
    text-decoration:none;
    position:relative;
}
#tooltip-span {
  margin-top:10px;
}
.tooltip .tipspan {
    display:none;
    z-index: 998;
    position: absolute;
    color: var(--ion-color-dark);
    background: var(--ion-color-light);
    border: 1px solid black;
    border-radius: 5px;
    padding-top: 15px;
    padding-left: 10px;
    padding-right: 10px;
    width:300px;
    -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
        -moz-animation: fadein 1s; /* Firefox < 16 */
        -ms-animation: fadein 1s; /* Internet Explorer */
          -o-animation: fadein 1s; /* Opera < 12.1 */
            animation: fadein 1s;
    
}

.tooltip:hover span {
    display:block;
    position:fixed;
    overflow:hidden;
    z-index: 999;
}
@keyframes fadein {
    0% { opacity: 0; }
    100%   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    0% { opacity: 0; }
    100%   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    0% { opacity: 0; }
    100%   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    0% { opacity: 0; }
    100%   { opacity: 1; }
}

@media screen and (min-width: 500px) {
  .tip:hover .tipText {
      visibility: visible;
      opacity: 1;
  }
  .tip2:hover .tipText2 {
      visibility: visible;
      opacity: .95;
  }
}

// Data Table Styling
.container { 
    display: flex;
    flex-wrap: wrap;
}
/* On screens that are 992px wide or less, go from four columns to two columns */
@media screen and (max-width: 992px) {
  .dataTable {
    flex: 50%;
  }
}

/* On screens that are 600px wide or less, make the columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .container {
    flex-direction: column;
  }
  .column {
    width: 100%;
    display: block;
    margin-bottom: 5px;
  }
  .tabContainer {
    column-count: 1 !important;
  }
}
.dataTable {
    width: 100%;
    //border-bottom: 1px solid rgba(184,184,184,.3);
    margin-bottom: 7px;
    margin-left: 0px;
    flex: 25%;
}
/* Float four columns side by side */
.column {
  float: left;
  width: 50%;
  padding: 0 10px;
}
.table {
    max-height: 800px;
    overflow-y: scroll;
}
.table::-webkit-scrollbar {
    display: none;
}

.largetable {
    max-height: 100%;
    overflow-y: scroll;
}

.largetable::-webkit-scrollbar {
    display: none;
}

.smalltable {
    max-height: 40vh;
    overflow-y: scroll;
}

.smalltable::-webkit-scrollbar {
    display: none;
}

.smallertable::-webkit-scrollbar {
    display: none;
}

.smallertable {
    max-height: 20vh;
    overflow-y: scroll;
}

.header-row {
      font-weight: 500;
      border-bottom: 1px solid rgba(184,184,184,.9);
      font-size: 1.4vh;
}
.data-row {
  border-bottom: 1px solid Grey;
}
.data-row:hover {
  background: WhiteSmoke;
}

.rowImage {
    text-align: center;
	margin: auto;
	width: 75px;
	height: 30px;
	vertical-align: middle;
	display: table-cell;
	
    img {
		max-height: 100%;
		max-width: 35px;
    }
}
.left {
    text-align: left;
    bottom: 0;
    vertical-align: bottom;
}
.right {
    text-align: right;
    bottom: 0;
    vertical-align: bottom;
}
.green {
    color: Lime;
}
* {
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;    
}

.native-textarea.sc-ion-textarea-md {
    display: inline-table;
}

.body {
  background: var(--ion-item-background);
  color: var(--ion-color-dark);
  border-top: 1px solid LightGrey;
  border-bottom: 1px solid LightGrey;
  
  ion-button {
    margin: 2px;
  }
}
.footer {
  padding: 15px;
  text-align: right;
}
.tabcontent {
    display: none;
    border-top: none;
    width: 100%;
    margin: auto;
}
.sc-ion-label-md-h{
  white-space: normal !important;
}
.draggable-box {
  width: 500px;
  max-width: 95%;
  max-height: 600px;
  border: solid 2px #ccc;
  color: var(--ion-color-light);
  cursor: move;
  background: var(--ion-color-dark);
  border-radius: 10px;
  position: absolute;
  padding: 15px;
  right: 10px;
  top: 50%;
  z-index: 100;
  transition: box-shadow 200ms cubic-bezier(0, 0, 0.2, 1);
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
              0 2px 2px 0 rgba(0, 0, 0, 0.14),
              0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.draggable-box:active {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
              0 8px 10px 1px rgba(0, 0, 0, 0.14),
              0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.draggable-box ion-button {
  width: 30%;
}
.draggable-box h6 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-weight: bolder;
  font-size: 1.1em;
}
.draggable-box ion-item {
  font-size: .96em;
  --padding-start: 0px !important;
  --inner-padding-end: 0px !important;
  --background: var(--ion-color-dark);
  color: var(--ion-color-light);
  --color-hover: var(--ion-color-light);
  border-radius: 10px;
}

.draggable-box ion-select {
  width: 100%;
  text-align: right;
  border: 1px solid var(--ion-color-light);
  border-radius: 5px;
}
.draggable-box ion-icon {
  cursor: pointer !important;
  margin-right: 5px;
  vertical-align: middle;
  color: var(--ion-color-light);
  z-index: 200;
}
.draggable-box ion-list {
  border-radius: 10px;
  max-height: 160px;
  overflow-y: scroll;
  color: var(--ion-color-light);
  background: var(--ion-color-dark);
}
.draggable-box a {
  color: var(--ion-color-light);
}

.reg-form {
  width: 95%;
  left: 20%;
  margin: auto auto 20px;

  .horizontal {
    ion-button {
      float: right;
      margin: 15px;
    }
    
    ion-row {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
    ion-col:first-child {
      text-align: left;
      // max-width: 200px;
      /* border: 1px solid green; */
    }
    ion-col:not(:first-child) {
      text-align: right;
      /* border: 1px solid red; */
    }

    ion-select {
      width: 100%;
    }

    ion-toggle {
      margin: 0px;
      padding: 0px;
    }
    ion-grid {
      --ion-background-color: transparent !important;
    }
  }
}

.columnWide {
  max-width: 1000px;
}

.rounded-field {
  border-radius: 10px;
  border: 1px solid;
  border-color: var(--ion-color-primary);
  // margin: auto !important;
  --background: var(--ion-color-light);
  --highlight-color-valid: var(--ion-color-primary)
}

.border { 
  border: 1px solid LightGrey;
  border-radius: 3px;
}

.box {
  width: 100%;
  margin: auto;
  padding: 15px;
  margin-top:5px;
}

.map-banner {
  width: 100%;
  margin: 0 auto;
  position: absolute;
  min-height: 10px;
  background-color: var(--ion-item-background);
  padding: 5px;

  ion-row{
    @media screen and (max-width:350px) {
          justify-content: unset;
          margin: auto;
    }
  }
  .content::-webkit-scrollbar {
      display: none;
  }

  .content  {
      position: absolute;
      width: 100%;
      margin: 0 auto;
      min-height: 50px;
      border-radius: 15px;
      padding: 20px;
      -webkit-flex-flow: row wrap;
      flex-flow: row wrap;
      backdrop-filter: blur(2px);
      overflow-y: scroll;
  }

  .tab {
    max-height: 210px; 
    overflow-y: scroll; 
    padding-left: 20px; 
    padding-right: 20px;

    ion-icon {
          vertical-align: middle;
        display: inline-block;
          font-size: 1.8vh;
    }
    .text {
          display: inline-block;
          vertical-align: middle;
          margin: 0%;
          padding-left: 10px;
    }
  }

  .tab::-webkit-scrollbar {
    display: none;
  }

  .tab button {
    background-color: inherit;
    //background-color: var(--ion-color-dark);
    //background-color: #FFFFFF;
    border-radius: 5px;
    color: var(--ion-color-dark);
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 3px 5px;
    transition: 0.3s;
    font-size: 1.05em;
  }

  /* Change background color of buttons on hover */
  .tab button:hover {
      //background-color: #ddd;
      background-color: #A9A9A9;
  }

  /* Create an active/current tablink class */
  .tab button.active {
      background-color: #A9A9A9;
  }

  ion-list {
      background: transparent;
  }
}